<template>
  <div class="myProject">
    <TabPanes @tapHandleClick="tapHandleClick" :tabsList="tabsList" :activeName="activeName" @searchClick="searchClick" />
    <ProjectList :redDot="'redDot'" :list="projectDataList" :marginRight="20" :windowWidth="900" />
    <div class="top">
      <Pagination :total="totalCount" :pageSize="16" @changeCurrentPage="changeCurrentPage" v-if="projectDataList.length" />
    </div>
  </div>
</template>

<script>
import TabPanes from '@/components/common/tabPanes'
import ProjectList from '@/components/common/projectList'
import Pagination from '@/components/common/pagination'
import {myProject, statistics} from '@/api/user/user'
export default {
  name: 'myProject',
  components: {
    TabPanes,
    ProjectList,
    Pagination
  },
  data() {
    return {
      projectDataList: [],
      totalCount: 0,
      keyword: '',
      page: 1,
      tabsList: [
        {
          titel: '审核中',
          quantity: 0
        },
        {
          titel: '待投标',
          quantity: 0
        },
        {
          titel: '已投标',
          quantity: 0
        },
        {
          titel: '已选标',
          quantity: 0
        },
        {
          titel: '已完成',
          quantity: 0
        }],
      activeName: '审核中',
      status: 0,
      check_status: 0
    }
  },
  mounted() {
    this.project()
    this.statisticsObj()
  },
  methods: {
    statisticsObj() {
      statistics().then(res => {
        const data = res.data
        if (data.center || data.top) {
          this.tabsList[0].quantity = data.top.project_check
          this.tabsList[1].quantity = data.center.project_pending_bid
          this.tabsList[2].quantity = data.center.project_in_progress
          this.tabsList[3].quantity = data.center.project_evaluated
          this.tabsList[4].quantity = data.center.project_completed
        }
        // console.log('打印', res.data)
      })
    },
    changeCurrentPage(value) {
      this.page = value
      this.project()
    },
    tapHandleClick(index, label) { // tap的切换
      // console.log(index)
      if (label === '审核中') {
        this.status = ''
        this.check_status = 0
      } else {
        this.status = index - 1
        this.check_status = ''
      }
      this.project()
    },
    searchClick(input) { // 搜索事件
      this.keyword = input
      // console.log(input)
      this.project()
    },
    project() {
      const data = {
        keyword: this.keyword,
        psize: 16,
        page: this.page,
        check_status: this.check_status,
        status: this.status
      }
      myProject(data).then(res => {
        this.totalCount = res.data.count
        this.projectDataList = res.data.list
        // console.log('打印', res.data.list)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .myProject{
    padding: 32px 24px;
    background: #fff;
    margin-top: 12px;
    min-height: 583px;
    .top{
      margin: 45px auto 10px;
    }
  }
</style>
