<template>
  <el-tabs v-model="tapName" @tab-click="handleClick" class="flex_jcsb_ac marginBotton24">
    <el-tab-pane
      :label="item.titel"
      :name="item.titel"
      v-for="item in tabsList"
      :key="item.titel"
    >
      <template #label>
        <div class="quantity">{{item.quantity}}</div>
        <div class="flex_ac">
          <span class="tabsTitle">{{item.titel}}</span>
        </div>
      </template>
    </el-tab-pane>
    <el-input
      placeholder="搜索项目名称"
      @change="searchClick"
      v-model="input">
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
  </el-tabs>
</template>

<script>
export default {
  name: 'tabPanes',
  props: {
    tabsList: {
      type: Array,
      default: function() {
        return []
      }
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  watch: {
    activeName(val) {
      // console.log('val', val)
      this.tapName = val
    }
  },
  mounted() {
    this.tapName = this.activeName
  },
  data() {
    return {
      input: '',
      tapName: '审核中'
    }
  },
  methods: {
    handleClick(tap) {
      this.$emit('tapHandleClick', tap.index, tap.label)
    },
    searchClick() {
      this.$emit('searchClick', this.input)
    }
  }
}
</script>

<style lang="scss" scoped>
.marginBotton24{
  margin-bottom: 24px;
}
/deep/{
  .el-input--prefix .el-input__inner{
    background: #FBFBFB;
    width: 280px;
    height: 36px;
    padding-left: 34px;
  }
  .el-tabs__header{
    margin: 0;
    flex: 1;
  .el-tabs__item{
    .tabsIcon{
      width: 24px;
    }
    .quantity{
      background: #F9D900;
      border: 1px solid #FFFFFF;
      border-radius: 8px;
      line-height: 16px;
      min-width: 18px;
      text-align: center;
      padding: 1px;
      font-size: 12px;
      color: #fff;
      position: absolute;
      top: 0;
      margin-left: 50px;
    }
    .tabsTitle{
      font-size: 18px;
    }
    &.is-active{
      .tabsTitle{
        color: #1166CF;
      }
    }
    &:hover{
      color: #1166CF;
    }
  }
  .el-tabs__active-bar{
    background-color: #1166CF;
    height: 4px;
    border-radius: 8px;
 }
 .el-tabs__nav-wrap::after{
    height: 0;
  }
 }
}
</style>
